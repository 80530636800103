.mcActivity {
    padding: 2rem;
    padding-bottom: 30px;
    border-radius: 1.5rem;
    margin-bottom: 2rem;
    position: relative;
    background-color: $col-light-blue;

    &.reveal-answer {
        background-color: $col-light-green;
        .custom-checkbox {
            background-color: #e9ecee;
            border: 1px solid #adb8c0;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
                inset 15px 10px -12px rgba(255, 255, 255, 0.1);
        }
        .custom-checkbox,
        .input-label {
            pointer-events: none;
        }
        .answer {
            &.correct {
                .custom-checkbox {
                    border: 1px solid $col-primary;
                }
            }
            &.incorrect {
                .custom-checkbox {
                    background-color: transparent;
                    border: 1px solid $col-grey;
                    &:checked {
                        background-color: $col-light-red;
                        border: 1px solid $col-red;
                        color: $col-red;
                        &:after {
                            color: $col-red;
                        }
                    }
                }
            }
            &.incorrect,
            &.correct {
                &.answered-incorrect {
                    color: $col-red;
                    .custom-checkbox {
                        background-color: $col-light-red;
                        border: 1px solid $col-red;
                    }
                }
            }
        }
        .mc-btn {
            font-weight: 600;
            background-color: $col-secondary;
        }
    }

    .mc-btn {
        position: absolute;
        min-width: 260px;
        height: 60px;
        bottom: -30px;
        left: 50%;
        border-radius: 2.5rem;
        padding: 1rem;
        text-align: center;
        color: #fff;
        background-color: $col-primary;
        transform: translateX(-50%);
        transition: all 0.1s linear;

        &:hover {
            cursor: pointer;
            transform: translate(-50%, -4px);
        }

        &.disabled {
            // background-color: $col-grey;
            box-shadow: none !important;
            color: $normal-text;
            pointer-events: none;
        }
    }

    &.blue {
        .mc-btn {
            background-color: $col-primary;
        }
        background-color: $col-light-blue;
    }
    &.green {
        .mc-btn {
            background-color: $col-secondary;
        }
        background-color: $col-light-green;
    }
    &.pink {
        .mc-btn {
            background-color: $col-pink;
        }
        background-color: $col-light-pink;
    }
    &.yellow {
        .mc-btn {
            background-color: $col-yellow;
        }
        background-color: $col-light-yellow;
    }
    &.purple {
        .mc-btn {
            background-color: $col-light-purple;
        }
        background-color: $col-light-purple;
    }
    &.red {
        .mc-btn {
            background-color: $col-red;
        }
        background-color: $col-light-red;
    }

    ul,
    ol {
        padding-left: 0;
        @include media-breakpoint-up(md) {
            padding-left: 2rem;
        }
    }
}
