nav {
    min-height: 100px;
    min-width: 450px;
}

.vch-logo {
    margin: 0 auto;

    img {
        max-width: 10rem;
    }
}

#close-nav-btn {
    position: absolute;
    top: 2.5rem;
    left: 1.5rem;
}

// responsive size of icon buttons
nav {
    .icon-btn {
        max-width: 54px;
        max-height: 54px;
    }

    .icon-btn-lg {
        max-width: 42px;
        max-height: 42px;
    }

    .icon-btn,
    .icon-btn-lg {
        &.stroke-icon path {
            fill: none;
            stroke-width: 3px;
            stroke: $normal-text;
            transition: all 0.4s ease;

            &:hover {
                fill: $normal-text;
            }
        }
    }
}

// search btn
/*** STYLES ***/
.search-container {
    min-width: 56px;
    min-height: 35px;

    &.active,
    &:hover,
    &:focus-within {
        @include media-breakpoint-down(md) {
            width: 100%;
            left: 0;
            top: 0.5rem;
            position: absolute;
            background-color: #fff;
            height: 50px;
        }
        .search {
            margin-right: 0px;
            margin-top: 0;
            margin-bottom: 0;
            border-width: 3px;
            border-radius: 12px;
            height: 36px;

            &:after {
                height: 0px;
            }

            @include media-breakpoint-down(md) {
                width: 90%;
                right: 1.25rem;
                position: absolute;
            }

            @include media-breakpoint-up(md) {
                // position: relative;
                width: 18vw;
                margin-right: 0px;
                margin-top: 0;
                margin-bottom: 0;
                border-width: 3px;
                border-radius: 12px;
                height: 36px;
            }
        }
    }
}

.search {
    display: inline-block;
    position: absolute;
    height: 28px;
    width: 28px;
    box-sizing: border-box;
    margin: 0px 8px 7px 4px;
    padding: 1px 9px 3px 9px;
    border: 3.5px solid $normal-text;
    border-radius: 25px;
    transition: all 200ms ease;
    cursor: text;

    &:after {
        content: "";
        position: absolute;
        width: 3.5px;
        height: 12px;
        right: -3px;
        top: 17px;
        background: $normal-text;
        border-radius: 10px;
        transform: rotate(-45deg);
        transition: all 200ms ease;
    }

    input {
        width: 100%;
        border: none;
        box-sizing: border-box;
        font-family: Helvetica;
        font-size: 15px;
        color: inherit;
        background: transparent;
        outline-width: 0px;
        margin-bottom: 3px;
    }

    @include media-breakpoint-up(md) {
        display: inline-block;
        position: relative;
        height: 28px;
        width: 28px;
        box-sizing: border-box;
        margin: 0px 8px 7px 4px;
        padding: 1px 9px 3px 9px;
        border: 3.5px solid $normal-text;
        border-radius: 25px;
        transition: all 200ms ease;
        cursor: text;

        &:after {
            content: "";
            position: absolute;
            width: 3.5px;
            height: 12px;
            right: -3px;
            top: 17px;
            background: $normal-text;
            border-radius: 10px;
            transform: rotate(-45deg);
            transition: all 200ms ease;
        }

        input {
            width: 100%;
            border: none;
            box-sizing: border-box;
            font-family: Helvetica;
            font-size: 15px;
            color: inherit;
            background: transparent;
            outline-width: 0px;
        }
    }

    .autocomplete-input {
    }
    ul.autocomplete-result-list {
        right: 0;
        z-index: 100 !important;

        @include media-breakpoint-down(md) {
            max-width: 400px;
        }
        @include media-breakpoint-up(md) {
            min-width: 28rem;
        }
    }
}

//tablet and above

#side-menu {
    height: 100%;
    padding: 2rem;
    padding-top: 1.7rem;
    left: -450px;
    top: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    position: fixed;
    z-index: 9999;
    background: $light;
    transition: transform 0.3s ease-out;
    width: 450px;

    &.active {
        @include transform(translateX(450px));
    }

    @include media-breakpoint-up(md) {
        overflow-y: hidden;
        width: 450px;
        height: 100%;
        left: -450px;

        &.active {
            @include transform(translateX(450px));
        }
    }
}

ul.nav {
    width: 350px;
}

#menu-btn {
    transition: all 0.1s ease;
    &:hover,
    &:focus {
        cursor: pointer;
        transform: scale(1.1);
    }
}

#close-nav-btn,
.close-popup-btn svg {
    transition: all 0.1s ease;
    &:hover,
    &:focus {
        cursor: pointer;
        transform: scale(1.25);
    }
}

#overflow-nav {
    position: absolute;
    display: inline-flex;
    top: 2.5rem;
    right: 1.5rem;

    div {
        margin-bottom: 1rem;
    }
}

#nav-list {
    margin-top: 3em;
}

.nav-item {
    min-width: 200px;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }
    a,
    span {
        font-weight: 400;
        color: $normal-text;
        text-decoration: none;
        transition: all 0.1s linear;

        @include media-breakpoint-up(med) {
            font-size: 1.5rem;
        }

        .nav-text {
            margin-left: 0.5rem;
        }

        &:hover,
        &:focus {
            font-weight: 600;
            cursor: pointer;

            svg {
                // margin-right: 0.5rem;
            }

            .nav-text {
                margin-left: 1rem;
            }

            &#home-btn {
                // color: $col-primary;
                .icon path {
                    fill: $col-primary;
                }
            }
            &#myguide-btn {
                // color: $col-secondary;
                .icon path {
                    fill: $col-secondary;
                }
            }
            &#recovery-plan-btn {
                .icon path {
                    fill: $col-yellow;
                }
            }
            &#resources-btn {
                // color: $col-yellow;
                .icon path {
                    fill: $col-primary;
                }
            }
            &#faq-btn {
                // color: $col-pink;
                .icon path {
                    fill: $col-pink;
                }
            }
            &#about-btn {
                // color: $col-purple;
                .icon path {
                    fill: $col-purple;
                }
            }
            &#help-btn {
                // color: $col-red;
                .icon path {
                    fill: $col-red;
                }
            }
            &#feedback-btn {
                // color: $col-red;
                .icon path {
                    fill: $col-light-blue;
                }
            }
        }
        &.active {
            font-weight: 600;
            &#home-btn {
                .icon path {
                    fill: $col-primary;
                }
            }
            &#myguide-btn {
                .icon path {
                    fill: $col-secondary;
                }
            }
            &#recovery-plan-btn {
                .icon path {
                    fill: $col-yellow;
                }
            }
            &#resources-btn {
                .icon path {
                    fill: $col-primary;
                }
            }
            &#faq-btn {
                .icon path {
                    fill: $col-pink;
                }
            }
            &#about-btn {
                .icon path {
                    fill: $col-purple;
                }
            }
            &#help-btn {
                .icon path {
                    fill: $col-red;
                }
            }
            .nav-text {
                font-weight: 600;
            }
        }
        &.disabled {
            color: $col-grey;
            pointer-events: none;
        }
    }

    .icon {
        // margin-right: 1rem;
        margin-top: -0.25rem;

        @include media-breakpoint-up(sm) {
            max-width: 60px;
            max-height: 74px;
        }
    }
}

.sticky-nav {
    z-index: 50;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    background-color: $col-white;
    padding: 1.5rem 0;
}

.progress-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5px;
    box-shadow: 0 2px 3px $shadow-lighter;
}

.progress-bar {
    position: relative;
    width: 0%;
    height: 5px;
    background-color: $col-purple;
}

//autocomplete cs
.autocomplete-container {
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    display: inline-block;
}

.autocomplete-result {
    border-top: 1px solid #eee;
    padding: 16px;
}

.autocomplete-result-list {
    padding: 0;
    background: #fff;
    width: 100%;
}

.group {
    padding: 16px;
    text-transform: uppercase;
    font-size: 14px;
    background: rgba(0, 0, 0, 0.06);
}

.wiki-title {
    font-size: 20px;
    margin-bottom: 8px;
}

.wiki-snippet {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
}
