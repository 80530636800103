body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Calibri", Candara, Segoe, "Segoe UI", Arial, sans-serif;
}

body#body {
    font-size: 1.25em;
}

p {
    font-size: 0.875em;

    @include media-breakpoint-up(md) {
        font-size: 1em;
    }
}

@include media-breakpoint-up(md) {
    body#body {
        // font-size: 2rem;
    }
}

h1 {
    // font-family: sans-serif;
    font-weight: 200;
}

h2 {
    // font-family: sans-serif;
    font-weight: 600;
}

.hero-title {
    font-size: calc(0.75rem + 1.25vw);
    font-weight: 600;

    &.home-card {
        font-weight: 200;
    }

    @include media-breakpoint-up(md) {
        font-size: calc(1.375rem + 1.5vw);
        font-weight: 200;
    }
}

.mid-title {
    font-size: calc(1.325rem + 0.9vw);
    font-weight: 600;
}

.section-title {
    font-weight: 400;
    font-size: 1.15rem;
    text-transform: uppercase;
    padding-left: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.hero-text-content {
    p {
        margin-bottom: 0.15rem;
        line-height: 1.25rem;
        font-size: 0.75em;

        @include media-breakpoint-up(sm) {
            line-height: 1.5rem;
            font-size: 0.875em;
        }
    }
}

// Rich text field
.rich-text {
    p {
        margin-bottom: 1rem;
    }
    li p {
        margin-bottom: 0.15rem;
    }
    ol {
        padding-left: 1rem;
        li {
            list-style: auto;

            ol li {
                list-style: lower-alpha;
            }
        }
    }
    ul {
        padding-left: 1rem;
        li {
            list-style: disc;
        }
    }

    a.subsection-link,
    a {
        font-weight: 800;
        color: $col-red;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
    a {
        color: $col-primary;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    table {
        width: 100%;
        margin-bottom: 1rem;
    }

    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
        border-width: 2px;
        padding: 0.5rem;
        border-color: $normal-text;
    }

    figure, img {
        width: 100%;
    }
    iframe {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
}

.inline-link {
    text-decoration: none;

    &.small-text {
        font-size: 1.15rem;
    }

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}
