// General subsection element styling
.favourite-ui {
    background-color: $col-pink;
    padding: 1rem 1rem 0.1rem;
    border-radius: 12px;
    max-width: 400px;
    margin: 0 auto 2rem;

    h2 {
        font-size: 1.65rem;
        padding-top: 0.25rem;
    }

    .emoji {
        padding: 0 0 0 0.2rem;
    }

    @include media-breakpoint-up(md) {
        max-width: 600px;
    }
}

// Import other more specific element styling
@import "hotspots";
@import "accordions";
@import "multiple-choice";
