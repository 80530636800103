.emoji {
    max-height: 1.5rem;
}

figure {
    width: 100%;
    justify-content: center;
    display: flex;
}

iframe {
    body {
        background: transparent;
    }
}

// Progress indicators
.track-progress-container {
    position: relative;
    display: contents;

    .progress-col {
        position: relative;
        pointer-events: none;

        &::before {
            z-index: -1;
            content: " ";
            display: block;
            position: absolute;
            background-color: $col-grey;
            width: 4px;
            height: 100%;
            left: 50%;
            @include transform(translateX(-2px)); //translate half width to center
        }
    }
    &:first-of-type .progress-col::before {
        height: 50%;
        bottom: 0;
    }
    &:last-of-type .progress-col::before {
        height: 50%;
    }

    &.read .progress-marker {
        background-color: $col-secondary;

        &::after {
            // color: #FFF;
            content: " ";
            display: block;
            width: 1em; // the short bar of the mark is half as long as the long bar
            height: 1.6em;
            border: solid white;
            border-width: 0 7px 7px 0; // creates the inverted "L" shape
            position: absolute;
            left: 30%;
            top: 10%;
            -webkit-transform: rotate(42deg); // tilts the shape
            -moz-transform: rotate(42deg);
            -o-transform: rotate(42deg);
            transform: rotate(42deg);
        }
    }
}

.progress-marker {
    position: relative;
    background-color: $col-yellow;
    border-radius: 100%;
    width: 3rem;
    height: 3rem;
    margin: auto;
}

// Key Points
.subtitle-card {
    margin-bottom: 1rem;
    h2 {
        padding: 1rem 1.5rem 0.5rem;
        border-radius: 8px;
        width: fit-content;
    }
    &.yellow h2,
    &.col-light-yellow h2 {
        background-color: $col-light-yellow;
    }
    &.blue h2,
    &.col-light-blue h2 {
        background-color: $col-light-blue;
    }
    &.col-primary h2 {
        background-color: $col-primary;
    }
    &.col-secondary h2 {
        background-color: $col-secondary;
    }
    &.col-pink h2 {
        background-color: $col-pink;
    }
    &.col-purple h2 {
        background-color: $col-purple;
    }
    &.col-light-purple h2 {
        background-color: $col-light-purple;
    }
}

// Overlay
#overlay {
    display: block;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 900;
    opacity: 0;
    transition: visibility 0s, opacity 0.4s linear;

    &.active {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.4s linear, visibility 0s;
    }
}

// breadcrumbs
.breadcrumbs {
    color: #9e9e9e;
    padding-left: 0;
    li {
        display: inline-block;
    }
    a {
        color: #9e9e9e;
        text-decoration: none;

        &.selected {
            color: #161616;
            pointer-events: none;
        }

        &:hover {
            color: #161616;
            text-decoration: underline;
        }
    }
}

.img-bleed {
    overflow: visible;
    svg,
    img {
        width: 100%;
    }
}

input.custom-checkbox {
    width: 24px;
    height: 24px;
    -webkit-appearance: none;
    background-color: #fff;
    border: 1px solid #000;
    padding: 9px;
    border-radius: 1px;
    display: inline-block;
    position: relative;

    &:checked {
        background-color: #e9ecee;
        border: 1px solid #adb8c0;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
            inset 15px 10px -12px rgba(255, 255, 255, 0.1);
        color: #99a1a7;

        &:after {
            content: "\2714";
            font-size: 20px;
            position: absolute;
            top: 0px;
            left: 3px;
            color: $col-primary;
            line-height: 25px;
        }
    }
}

.custom-checkbox,
.input-label {
    &:hover {
        cursor: pointer;
    }
}

.bg-colour-block-container {
    position: relative;
    min-width: 450px;
    margin-bottom: -1.5rem;
    padding: 4rem 0;
}

.bg-colour-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    &.blue {
        background-color: $col-light-blue;
    }
    &.green {
        background-color: $col-light-green;
    }
    &.pink {
        background-color: $col-light-pink;
    }
    &.yellow {
        background-color: $col-light-yellow;
    }
    &.purple {
        background-color: $col-light-purple;
    }
    &.red {
        background-color: $col-light-red;
    }
}

// FAQ BUBBLES
.question-bubbles,
.answer-bubbles {
    p {
        position: relative;
        border-radius: 35px;
        width: 400px;
        padding: 1.5rem 2rem;
        color: $normal-text;
        margin-bottom: 0.35rem;
    }
}
.question-bubbles {
    p {
        background-color: $col-light-blue;
        font-weight: 600;
        &:last-of-type:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0%;
            width: 0;
            height: 0;
            border: 20px solid transparent;
            border-top-color: $col-light-blue;
            border-bottom: 0;
            border-left: 0;
            margin-left: 2px;
            margin-bottom: -8px;
            transform: rotate(30deg);
        }
    }
}

.answer-bubbles {
    p {
        background-color: $col-secondary;
        &:last-of-type:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0%;
            width: 0;
            height: 0;
            border: 20px solid transparent;
            border-top-color: $col-secondary;
            border-bottom: 0;
            border-left: 0;
            margin-left: 0;
            margin-bottom: -6px;
            transform: rotate(60deg);
        }
    }
}

// Glider Slides re-styling
.popup-container {
    .glider-dot {
        &.active {
            background: $col-primary;
        }
    }
}

// Hotspots re-styling
.lg-container {
    .lg-hotspot {
        .lg-hotspot__button {
            border: 1px solid $col-lighter-blue;
            &:after {
                border: 3px solid white;
                background-color: $col-primary;
            }
        }
        &:hover, &:focus {
            .lg-hotspot__button {
            border: 1px solid $col-primary;
            cursor: pointer;
            &:after {
                border: 3px solid white;
                background-color: $col-primary;
            }
        }
        }

        .lg-hotspot__label {
            width: 20em;
        }
    }
}
