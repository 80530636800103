//UTILITY CLASSES
.shadow {
    box-shadow: 0 6px 20px $shadow;
}

.shadow-top {
    box-shadow: 0 -6px 20px $shadow-lighter;
}

.bg-white {
    background-color: #FFF;
}

.img-rounded {
    border-radius: 15px;

    @include media-breakpoint-up(md){
        border-radius: 30px;
    }
}

.show-on-dark-mode {
    display: none;
}

.display-none {
    display: none !important;
}

.justify-bottom {
    position:absolute;
    bottom: 0;
}

.unstyled-link {
    text-decoration: none;
    color: unset;
}

.min-height-500 {
    min-height: 500px;
}

.disabled {
    pointer-events: none;
}

.display-md {
    @include media-breakpoint-down(md){
        display: none;
    }
}

.display-none-md {
    @include media-breakpoint-up(md) {
        display: none;
    }
}

.overflow-y-scroll {
    overflow-y: auto;
}