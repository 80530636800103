.results-header {
    background-color: #000;
    padding: 0.74rem 1rem;
    color: #FFF;
}

.search-results-container {
    text-overflow: ellipsis;
    box-shadow: 0 6px 20px $shadow;

    a {
        text-decoration: unset;
        color: unset;
    }
    li {
        transition: all 0.2s linear;
        &:hover, &:focus {
            background-color: $col-secondary;
            color: $normal-text;
        }
    }

    @include media-breakpoint-down(md) {
        max-width: 50vw;
        padding: 0;
        margin: 0;
        left: 0;
    }
}

.search-title {
    font-weight: 400;
}

.search-snippet {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-left: 0.5rem;
    
    font-size: 1rem;

    ul {
        padding-left: 0.75rem;
    }
    li {
        list-style: disc;
        margin-bottom: 0.05rem;
    }
    p {
        margin-bottom: 0.15rem;
    }
}
