body.dark-mode {
    background-color: $darkmode-primary;
    color: $darkmode-text;

    .banner {
        background-color: $darkmode-secondary;
    }

    .bg-colour-block-container {
        color: $normal-text;
    }

    //CARDS
    .card {
        background-color: $darkmode-primary;
        border-color: $darkmode-secondary;
        color: $darkmode-text;

        .card-title {
            color: $darkmode-text;

            &.bg-white {
                color: $normal-text;
            }
        }

        &.img-card,
        .subsection-img-container {
            background-color: transparent;
            border-color: transparent;
        }
        &.page-summary-card {
            background-color: $darkmode-secondary;
            border-color: $darkmode-secondary;
        }

        &.myguide-card {
            a {
                color: $darkmode-text;
                text-decoration-color: $col-light-purple;

                &:hover,
                &:focus {
                    text-decoration-color: $darkmode-text;
                }
                &.read {
                    color: $col-light-purple;

                    &:hover,
                    &:focus {
                        color: $darkmode-text;
                        text-decoration-color: $darkmode-text;
                    }
                }
            }
        }
    }

    .show-on-light-mode {
        display: none;
    }

    .show-on-dark-mode {
        display: block;
    }

    // FAVOURITES
    table.fav-table td a {
        color: $darkmode-text;
    }

    //NAVBAR
    #side-menu {
        background-color: $darkmode-primary;
    }
    .sticky-nav {
        background-color: $darkmode-primary;
    }
    .progress-bar {
        background-color: $darkmode-secondary;
    }

    .icon.dm-responsive {
        path {
            fill: $darkmode-secondary;
        }

        &.stroke-icon {
            path {
                fill: none;
                stroke: $darkmode-secondary;
            }
            &.dm-toggle path {
                fill: $darkmode-secondary;
                stroke-width: 1px;
            }
        }

        &.icon-btn.stroke-icon path {
            &:hover {
                fill: $darkmode-secondary;
            }
        }

        &.dm-lighter {
            path {
                fill: $darkmode-text;
            }

            &.stroke-icon {
                path {
                    fill: none;
                    stroke: $darkmode-text;
                }
                &.dm-toggle path {
                    fill: $darkmode-text;
                    stroke-width: 1px;
                }
            }

            &.icon-btn.stroke-icon path {
                &:hover {
                    fill: $darkmode-text;
                }
            }
        }
    }

    .icon-and-label .icon-btn {
        path {
            fill: $darkmode-text;
        }
        &.stroke-icon {
            path {
                fill: none;
                stroke: $darkmode-text;
            }
            &.dm-toggle path {
                fill: $darkmode-text;
                stroke-width: 1px;
            }
        }
    }

    .nav-item a,
    .nav-item span {
        color: $darkmode-text;

        &#about-btn {
            &:hover,
            &:focus,
            &.active {
                .icon path {
                    fill: $col-light-purple;
                }
            }
        }
    }

    .popup {
        background-color: $darkmode-primary;
    }

    .rich-text {
        a.subsection-link {
            color: $col-red;
        }
        a {
            color: $col-secondary;
        }
    }

    .search {
        border: 4px solid $darkmode-secondary;
        color: $darkmode-text;

        &:after {
            background: $darkmode-secondary;
        }

        input {
            &::placeholder {
                color: $darkmode-secondary;
            }
        }
    }

    .search-container {
        background-color: $darkmode-primary;
    }

    .search-results-container {
        .results-header {
            background-color: $darkmode-secondary;
        }
    }

    .autocomplete-result,
    .autocomplete-result-list {
        background: $darkmode-primary;
    }

    .autocomplete-result {
        border-top: 1px solid $darkmode-secondary;

        li .wiki-snippet {
            color: $darkmode-text !important;
        }
    }

    footer .inline-link {
        color: $col-secondary;
    }

    //TOOLTIPS HIGHLIGHTS
    .glossary-item {
        background-color: $col-light-blue;
        color: #222;
    }

    .glossary-tooltip {
        background: #fff;
        color: #222;

        &::before {
            //ARROW COLOUR
            border-bottom: 5px solid #fff;
        }
    }

    //BREADCRUMBS
    .breadcrumbs,
    .breadcrumbs a {
        color: $darkmode-secondary;
    }
    .breadcrumbs a {
        &.selected {
            color: $darkmode-text;
        }
        &:hover {
            color: $darkmode-text;
        }
    }

    // SUBSECTION UI
    .favourite-ui,
    .subtitle-card,
    .mcActivity,
    .accordion {
        color: $normal-text;

        .rich-text {
            thead,
            tbody,
            tfoot,
            tr,
            td,
            th {
                border-color: $normal-text;
            }
        }
    }

    .solo-accordion,
    .solo-accordion a {
        color: $normal-text;
    }

    // SURVEY UI
    .big-btn {
        &:hover,
        &:focus {
            color: $normal-text;
        }
    }

    .survey-input {
        &:checked + .big-btn {
            color: $normal-text;
        }
    }

    .big-btn.survey-btn {
        color: #fff;

        &.disabled {
            color: #000;
        }
    }

    // Misc Buttons
    .launch-btn {
        color: $darkmode-primary;
    }

    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
        border-color: $darkmode-text;
    }
    // Glider Slides re-styling
    .popup-container {
        .glider-dot {
            &.active {
                background: $col-secondary;
            }
        }
        .small-btn {
            &:hover {
                border-color: $col-secondary;
                background-color: $col-secondary;
                color: $normal-text;
            }
        }
    }
    #help-popup,
    #welcome-popup {
        .instruction-container {
            background-color: transparent;
            .kbInstruction {
                color: $col-light-blue;
            }
        }
    }
}
