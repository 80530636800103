.accordion {
    // trying example styles
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}

.a-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10px;

    &.active {
        .a-header {
            border-radius: 1rem 1rem 0 0;
            span {
                &::before {
                    transform: rotate(0deg);
                }
            }
        }

        .a-body {
            padding-top: 3.5rem;
            opacity: 1;
            max-height: 10000px;

            @include media-breakpoint-up(md) {
                max-height: 5000px;
            }
        }
    }

    &.blue,
    &.col-primary {
        .a-header {
            background-color: $col-light-blue;
        }
        .a-body {
            background-color: $col-lighter-blue;
        }
    }
    &.green,
    &.col-secondary {
        .a-header {
            background-color: $col-secondary;
        }
        .a-body {
            background-color: $col-light-green;
        }
    }
    &.pink, &.col-pink {
        .a-header {
            background-color: $col-pink;
        }
        .a-body {
            background-color: $col-light-pink;
        }
    }
    &.yellow, &.col-yellow {
        .a-header {
            background-color: $col-yellow;
        }
        .a-body {
            background-color: $col-light-yellow;
        }
    }
    &.purple, &.col-purple {
        .a-header {
            background-color: $col-accordion-purple;
        }
        .a-body {
            background-color: $col-lighter-purple;
        }
    }
    &.red, &.col-red {
        .a-header {
            background-color: $col-accordion-red;
        }
        .a-body {
            background-color: $col-light-red;
        }
    }
    &.col-light-blue {
        .a-header {
            background-color: $col-light-blue;
        }
        .a-body {
            background-color: $col-lighter-blue;
        }
    }
}

.a-header {
    margin: 0;
    position: relative;
    padding: 20px 80px 20px 30px;
    width: 100%;
    font-weight: 400;
    display: block;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    border-radius: 1rem;
    z-index: 10;

    &:hover {
        cursor: pointer;
        span {
            transform: scale(1.15);
        }
    }

    span {
        display: block;
        position: absolute;
        height: 100%;
        width: 30px;
        right: 20px;
        top: 0;
        pointer-events: none;
        transition: transform 0.1s linear;
        &:after {
            content: "";
            width: 30px;
            height: 3px;
            border-radius: 30px;
            background-color: $normal-text;
            position: absolute;
            top: 50%;
        }
        &:before {
            content: "";
            width: 30px;
            height: 3px;
            border-radius: 30px;
            background-color: $normal-text;
            position: absolute;
            top: 50%;
            transform: rotate(90deg);
            transition: all 0.3s ease-in-out;
        }
    }
}

.a-body {
    width: 100%;
    transition: all 0.6s ease-in-out;
    opacity: 0;
    height: auto;
    max-height: 0;
    overflow: hidden;
    padding: 1rem 30px;
    margin-top: -2rem;
    border-radius: 1rem;
    z-index: 5;

    figure {
        display: flex;
        justify-content: center;
    }
}

.resources-accordion {
    .a-body {
        a {
            color: $normal-text;
            .resource-emoji {
                text-decoration: none;
            }
        }
        li {
            margin-bottom: 1rem;
        }
    }
}
