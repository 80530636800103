.btn {
    border-radius: 100px;
    padding: 1rem;
    font-weight: 600;
    font-size: 30pt;
    width: 392px;
    height: 92px;
    color: $normal-text;
        display: flex;
    align-items: center;
    justify-content: center;
}

.icon-btn,
.icon-btn-lg {
    &:hover {
        cursor: pointer;
    }
}

.icon-and-label {
    display: flex;
    flex-flow: column;
    justify-content: center;
    flex: 0 0 0;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 40;
    width: 50px;
    display: inline-flex;
    align-items: center;
    text-align: center;
    transition: transform 0.2s linear;

    .icon-btn {
        margin: 0 auto;
    }
    &:hover, &:focus {
        cursor: pointer;
        .icon-btn {
            transform: scale(1.05);
        }
    }
    &#print-btn {
        top: 6rem;
    }
}

.top-arrow-btn,
.bottom-arrow-btn,
.small-btn,
.btn,
.subsection-link .card,
.icon-btn.icon-social {
    transition: transform 0.1s linear;
    &:hover, &:focus {
        cursor: pointer;
        transform: translateY(-2px);
    }
} 

.left-arrow-btn {
    transition: transform 0.1s linear;
    &:hover, &:focus {
        cursor: pointer;
        transform: translateX(-4px);
    }
}
.right-arrow-btn {
    transition: transform 0.1s linear;

    &:hover, &:focus {
        cursor: pointer;
        transform: translateX(4px);
    }
}

.home-card-btn {
    transition: transform 0.1s linear;

    .card-title {
        transition: transform 0.1s linear;
    }
    &:hover, &:focus {
        cursor: pointer;

        .card-title {
            transform: translateY(-3px);
        }
    }
}

.subsection-link .card {
    transition: all 0.1s linear;
    &:hover, &:focus {
        cursor: pointer;
        transform: translateY(0);
        box-shadow: 0 3px 10px $shadow-lighter;
    }
} 

//arrow btns
.left-arrow-btn,
.top-arrow-btn,
.bottom-arrow-btn,
.right-arrow-btn {
    display: inline-block;
    text-align: center;
    width: 4em;
    height: 4em;
    border: 0.5em solid $col-white;
    background-color: $col-white;
    border-radius: 50%;

    &::after {
        content: "";
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        border-top: 0.15em solid $normal-text;
        border-right: 0.15em solid $normal-text;
    }
}

// a:hover .left-arrow-btn, a:hover .top-arrow-btn, a:hover .bottom-arrow-btn, a:hover .right-arrow-btn{
//     border: 0.5em solid #e74c3c;
//   }

a:hover .left-arrow-btn:after,
a:hover .top-arrow-btn:after,
a:hover .bottom-arrow-btn:after,
a:hover .right-arrow-btn:after {
    border-top: 0.15em solid $col-yellow;
    border-right: 0.15em solid $col-yellow;
}

.left-arrow-btn {
    margin-right: 0.5em;
}

.left-arrow-btn:after {
    margin-top: 0.8em;
    margin-left: 0.4em;
    -moz-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.top-arrow-btn {
    margin-right: 0.75em;
}

.top-arrow-btn:after {
    margin-top: 1.4em;
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.bottom-arrow-btn {
    margin-left: 0.75em;
}

.bottom-arrow-btn:after {
    margin-top: 0.6em;
    -moz-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}

.right-arrow-btn {
    margin-left: 0.5em;
}

.right-arrow-btn:after {
    margin-top: 0.8em;
    margin-left: -0.4em;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 0.75rem auto;
    @include media-breakpoint-up(md) {
        margin: auto auto 1rem;
    }
    &input {
        /* Hide default HTML checkbox */
        opacity: 0;
        width: 0;
        height: 0;
    }
}

/* SLIDERS */
.switch-input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
        position: absolute;
        content: "";
        height: 36px;
        width: 36px;
        left: -2px;
        bottom: -1px;
        background-color: $col-purple;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
    &.round {
        /* Rounded sliders */
        border-radius: 34px;
        &:before {
            border-radius: 50%;
        }
    }
}

input {
    &:checked + .slider {
        background-color: $col-light-purple;
    }
    &:focus + .slider {
    }
    &:checked + .slider:before {
        @include transform(translateX(32px));
    }
}

// Big multiline buttons
.big-btn {
    min-height: 5rem;
    text-align: center;
    font-weight: 600;
    border: 2px solid $col-grey;
    border-radius: 4rem;
    padding: 0.75rem 1.5rem;
    margin: 0.4rem 0.4rem 0.6rem 0.4rem;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;

    &:focus,
    &:hover {
        border: 2px solid $col-secondary;
        background-color: $col-secondary;
        // box-shadow: 0 6px 20px $shadow-lighter;
        cursor: pointer;
    }

    .btn-content {
        display: flex;
        flex-direction: column;
        height: 3.25rem;
        justify-content: center;

        .btn-title {
            display: block;
            font-weight: bold;
            font-size: 1.5rem;
            margin: 0.5rem 0;
        }

        .btn-subtext {
            margin: -0.5rem 0 0.75rem 0;
            font-size: 1rem;
            font-weight: 600;
        }
    }
}

.small-btn-container {
    width: 100%;
    justify-content: center;

    @include media-breakpoint-up(md) {
        justify-content: flex-start;
    }
}

.default-btn-container {
    max-width: 100%;
    max-width: 200px;

    @include media-breakpoint-up(md) {
        max-width: 200px;
    }
    .small-btn {
        border: 2px solid $col-primary;
        background-color: $col-primary;
        color: $col-white;
        font-size: 1.25rem;

        &:focus,
        &:hover {
            box-shadow: 0 6px 20px $shadow-lighter;
            cursor: pointer;
        }
    }
}

.small-btn, .glider-contain .small-btn {
    text-align: center;
    font-weight: 400;
    font-size: 1.5rem;
    color: $normal-text;
    border: 2px solid $col-light-blue;
    background-color: $col-light-blue;
    border-radius: 4rem;
    padding: 0.5rem 0.75rem;
    margin: 0.4rem 0.4rem 0.6rem 0.4rem;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    height: 2.25rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    min-width: 150px;

    @include media-breakpoint-up(md) {
        font-size: 1.75rem;
        line-height: 1.75rem;
        padding: 0.45rem 1.5rem 0.25rem;
        margin: 0.4rem 0.4rem 0.6rem 0.4rem;
        height: 3rem;
        // border: 2px solid $col-white;
        // background-color: $col-white;
    }

    &:focus,
    &:hover {
        border: 2px solid $col-primary;
        background-color: $col-primary;
        color: $col-white;
        // box-shadow: 0 6px 20px $shadow-lighter;
        cursor: pointer;
    }
}

.small-btn, .big-btn {
    &.stroke-btn {
        background-color: transparent;
        color: $col-primary;
    }
}

.half-btn-md .big-btn,
.half-btn-md.big-btn {
    .btn-title {
        font-size: 1rem;

        @include media-breakpoint-up(md) {
            font-size: 1.5rem;
        }
    }

    .btn-subtext {
        margin: -0.5rem 0 0.5rem 0;
        line-height: 1rem;
        font-size: 0.75rem;
        font-weight: 200;

        @include media-breakpoint-up(md) {
            margin: -0.25rem 0 0.75rem 0;
            font-size: 1rem;
            font-weight: 600;
        }
    }
}

.big-btn.survey-btn {
    min-height: 2rem;
    border: 2px solid $col-primary;
    background-color: $col-primary;
    color: #fff;
    width: 60%;
    min-width: 150px;

    &.disabled {
        border: 2px solid $col-grey;
        background-color: $col-grey;
        color: #000;
    }

    &#prev-btn {
        float: right;
    }
}
