@media print {
    @page {
        margin: 0.5cm;
    }

    /* Defining all page breaks */
    a,
    blockquote {
        page-break-inside: avoid;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .subtitle-card {
        page-break-after: avoid;
        page-break-inside: avoid;
    }
    img,
    .row {
        page-break-inside: avoid;
        page-break-after: avoid;
    }
    table,
    pre {
        page-break-inside: avoid;
    }
    ul,
    ol,
    dl {
        page-break-before: avoid;
    }

    p,
    ol,
    ul,
    li,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $normal-text;
    }

    body .no-print {
        display: none;
    }

    body .use-for-print {
        display: inherit;
    }

    img {
        max-width: 500px;
    }

    .subtitle-card h2 {
        padding-left: 0;
    }

    .print-text-bottom {
        display: flex;
        padding-bottom: 0;
        margin-bottom: 0;
        p {
            align-self: flex-end;
        }
    }

    footer {
        .print-footer {
            font-size: 6px;

            .vch-logo {
                &.show-on-light-mode {
                    display: block;
                }
                &.show-on-dark-mode {
                    display: none;
                }
                img {
                    max-width: 8rem;
                    color: $normal-text;
                }
            }
            .icon-social {
                width: 18px;
                &.icon.dm-responsive path {
                    fill: $normal-text;
                }
            }

            .row {
                // position: relative;
                // display: flex;
            }
        }
    }

    .rq-print-title {
        display: block !important;
    }

    .col-print-1 {
        width: 8% !important;
        max-width: 8% !important;
        float: left;
    }
    .col-print-2 {
        width: 16% !important;
        max-width: 16% !important;
        float: left;
    }
    .col-print-3 {
        width: 25% !important;
        max-width: 25% !important;
        float: left;
    }
    .col-print-4 {
        width: 33% !important;
        float: left;
    }
    .col-print-5 {
        width: 42% !important;
        float: left;
    }
    .col-print-6 {
        width: 50% !important;
        float: left;
    }
    .col-print-7 {
        width: 58% !important;
        float: left;
    }
    .col-print-8 {
        width: 66% !important;
        float: left;
    }
    .col-print-9 {
        width: 75% !important;
        float: left;
    }
    .col-print-10 {
        width: 83% !important;
        float: left;
    }
    .col-print-11 {
        width: 92% !important;
        float: left;
    }
    .col-print-12 {
        width: 100% !important;
        max-width: 100% !important;
        float: left;
    }
}

.rq-print-title {
    display: none;
}

footer .print-footer {
    // display: none;
}
