$col-primary: #0078AE;
$col-secondary: #C1D82F;
$col-light-blue: #ddf2fd;
$col-lighter-blue: #f0f9ff;
$col-light-green: #E7F0AF;
$col-yellow: #FFCC4F;
$col-light-yellow: #FEE6AA;
$col-pink: #F2CDD3;
$col-light-pink: #FAEBED;
$col-purple: #304180;
$col-light-purple: #697ECA;
$col-accordion-purple: #7A92E7;
$col-lighter-purple: #a7b6ec;
$col-red: #EC615B;
$col-accordion-red: #FB645E;
$col-light-red: #FFC8C6;

$normal-text: #000;
$col-grey: #C1C1C1;
$col-light-grey: #E8E8E8;
$col-white: #FFF;

$darkmode-primary: $col-purple;
$darkmode-secondary: $col-light-purple;
$darkmode-text: $col-light-blue;

$shadow: rgba(0, 0, 0, 0.6);
$shadow-lighter: rgba(0, 0, 0, 0.2);
