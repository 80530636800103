// Referenced from https://codepen.io/hixann/details/NvELLW
.glossary-item {
    display: inline-block;
    text-decoration: none;
    position: relative;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    background-color: $col-light-blue;
}

.glossary-tooltip {
    background: #fff;
    color: #222;
    z-index: 99;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 0px;
    right: -25px;
    padding: 1rem;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    width: 350px;
    box-shadow: 0 -1px 8px $shadow;
}

.tooltip-title {
    display: block;
    font-weight: 600;
    font-size: 1.5rem;
}

.tooltip-body {
    display: block;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.25rem;
    word-break: break-word;
}

.glossary-tooltip:before {
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    top: -10px;
    left: 20px;
}

.glossary-item:hover .glossary-tooltip {
    top: 36px;
    visibility: visible;
    opacity: 1;
}
