footer {
    border-top: 1px solid $col-grey;
    padding-top: 2rem;

    h4 {
        font-size: 1rem;
    }
    p {
        font-size: 0.75rem;
        margin-bottom: 0rem;
    }
    .socials-container {
        padding-left: 0;
        // margin-top: -1rem;
        ul {
            margin: 0;
            padding: 0;
        }
        li {
            margin: 0;
            padding: 0;
            margin: 0;
            max-width: 32px;
        }
    }

}

.icon-social {
    // padding: 0.25rem;
    width: 24px;
}