h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

ul,
ol {
    list-style: none;
    padding: 0;
}

fieldset {
    border: none;
}

input {
    border-radius: 0;
    margin: 0;
}

main {
    min-height: 100vh;
}