.body {
    transition: color 0.2s linear;
}

.page-container {
    max-width: 1200px;
    min-width: 450px;

    @include media-breakpoint-up(xl) {
        width: 1200px;
        display: block;
    }
}

.content-container-small {
    max-width: 1000px;
}

.col-0 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 0%;
    flex: 0 0 0%;
}

.pl-4 {
    padding-left: $spacer * 1.5 !important;
}