.launch-btn {
    font-size: 2.25rem;
    background-color: #fff;
    max-width: 300px;
    width: 10em;
    height: 1.75em;
    line-height: 3.15rem;
    padding-left: 2rem;
    transition: all 0.3s ease;

    @include media-breakpoint-up(md) {
        margin-top: 2rem;
        max-width: 7em;
        height: 1.85em;
        padding: 0.35em 0.35em 0.35em 2rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: 3.15rem;
        // max-width: 8em;
        height: 1.85em;
        line-height: 4.15rem;
        padding: 1rem;
    }

    &:hover, &:focus {
        background-color: $col-secondary;
        color: $normal-text;

        .launch-icon {
            circle {
                fill: #FFF;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    // .launch-btn {
    //     font-size: 2.5rem;
    //     line-height: 4rem;
    //     width: 100%;
    // }
}

.launch-icon {
    // margin-top: -10px;
    margin-left: 1.25rem;
    width: 2.85rem;
    height: 2.85rem;

    @include media-breakpoint-up(md) {
        // margin-top: -3px;
        width: 3.15rem;
        height: 3.15rem;
        margin-left: 1.25rem;
        circle {
            fill: $col-secondary;
        }
    }

    @include media-breakpoint-up(lg) {
        // margin-top: -6px;
        width: 4rem;
        height: 4rem;
    }
}
