.survey-form {
    .container-fluid {
        position: relative;
    }
}

.survey-section {
    position: relative;
    display: none;
    min-height: 14rem;
    transition: all 0.4s ease;

    &.active {
        display: block;
    }

    h2 {
        text-align: center;
        margin-bottom: 1.5rem;
    }

    .glider {}
}

.survey-btns-container {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .big-btn {
        flex: 1 1 100%;
        max-width: 100%;

        @include media-breakpoint-up(md) {
            flex: 1 1 45%;
            max-width: 49%;
        }
    }

    &.half-btn-md .big-btn {
        flex: 1 1 45%;
        max-width: 47%;

        @include media-breakpoint-up(md) {
            flex: 1 1 45%;
            max-width: 49%;
        }
    }
}

.survey-input {
    display: none;
    // position: absolute;
    &:checked + .big-btn {
        border: 2px solid $col-secondary;
        background-color: $col-secondary;
    }
}