.card {
    border-radius: 2rem;
    min-height: 200px;
    border: $col-grey 3px solid;
    color: $normal-text;
    transition: color 0.2s linear;

    h1,
    h2,
    h3,
    h4 {
        text-decoration: none;
    }

    @include media-breakpoint-down(md) {
        border-radius: 15px;
    }

    &.section {
        max-height: 400px;

        img {
            max-width: 66%;
            margin: 0 auto;
        }

        .card-title {
            color: $normal-text;
            font-weight: 400;
            font-size: 1.25rem;

            border-radius: 6px;
            padding: 0.5rem 1rem;
            width: fit-content;

            @include media-breakpoint-up(md) {
                font-size: 1.5rem;
            }
        }
    }

    .icon-btn {
        max-width: 32px;
    }

    &.myguide-card {
        .card-title {
            font-weight: 600;
            font-size: 1.25rem;
            margin-bottom: 0;
        }
        a {
            display: inline-block;
            color: $normal-text;
            font-size: 1rem;
            text-decoration: underline;
            text-decoration-color: $col-grey;
            margin-left: 0.25rem;

            &.read {
                color: $col-grey;
                &:hover {
                    color: $normal-text;
                }
            }

            &:hover,
            &:focus {
                text-decoration-color: inherit;
            }
        }

        .card {
            justify-content: center;
        }
        @include media-breakpoint-down(md) {
            .card {
                border-radius: 15px;
                min-height: unset;
            }
        }

        @include media-breakpoint-up(md) {
            .card-title {
                font-size: 2.5rem;
            }
            a {
                font-size: 1.5rem;
                padding-left: 1rem;
            }
        }
    }
}

.subsection-link .card,
.myguide-card.card {
    min-height: unset;
}

.subsection-link .card {
    .card-title {
        margin-bottom: 2rem;
    }
}

.card-title {
    color: $normal-text;
    font-weight: 600;
    font-size: 1.25rem;

    border-radius: 6px;
    padding: 0;
    width: fit-content;

    @include media-breakpoint-up(md) {
        font-size: 2rem;
        padding: 0.5rem 1rem;
    }
}

.card-img {
    max-height: 314px;
    max-width: 75%;
    margin-left: 12%;
    margin-right: auto;
}

.card-img-full {
    min-height: inherit;
    .img-container {
        min-height: inherit;
    }
    svg {
        min-height: inherit;
    }
}

.card-img-right {
    right: 0;
    top: 0;
    position: absolute;
    margin: auto 0 auto 40%;
}

.card.col-primary,
.subsection-img-container.col-primary {
    background-color: $col-primary;
    border-color: $col-primary;
    color: #fff;
}
.card.col-secondary,
.subsection-img-container.col-secondary {
    background-color: $col-secondary;
    border-color: $col-secondary;
}
.card.col-light-blue,
.subsection-img-container.col-light-blue {
    background-color: $col-light-blue;
    border-color: $col-light-blue;
}
.card.col-lighter-blue,
.subsection-img-container.col-lighter-blue {
    background-color: $col-lighter-blue;
    border-color: $col-lighter-blue;
}
.card.col-light-green,
.subsection-img-container.col-light-green {
    background-color: $col-light-green;
    border-color: $col-light-green;
}
.card.col-yellow,
.subsection-img-container.col-yellow {
    background-color: $col-yellow;
    border-color: $col-yellow;
}
.card.col-light-yellow,
.card.page-summary-card,
.subsection-img-container.col-light-yellow {
    background-color: $col-light-yellow;
    border-color: $col-light-yellow;
}
.card.col-pink,
.subsection-img-container.col-pink {
    background-color: $col-pink;
    border-color: $col-pink;
}
.card.col-light-pink,
.subsection-img-container.col-light-pink {
    background-color: $col-light-pink;
    border-color: $col-light-pink;
}
.card.col-purple,
.subsection-img-container.col-purple {
    background-color: $col-purple;
    border-color: $col-purple;
    color: $darkmode-text;
}
.card.col-light-purple,
.subsection-img-container.col-light-purple {
    background-color: $col-light-purple;
    border-color: $col-light-purple;
}
.card.col-light-grey,
.subsection-img-container.col-light-grey {
    background-color: $col-light-grey;
    border-color: $col-light-grey;
}

.card.invisible-card {
    background-color: transparent;
    border-color: transparent;
}

.subsection-img-container {
    min-height: 100px;
}

.hero-container .card {
    min-height: 300px;

    &.min-height-200 {
        min-height: 200px;
    }

    // @include media-breakpoint-up(sm) {
    //     &.min-height-unset {
    //         min-height: 300px;
    //     }
    // }

    @include media-breakpoint-up(md) {
        min-height: 400px;
    }

    // special styling of hero buttons at mobile breakpoint
    @include media-breakpoint-down(md) {
        margin-bottom: 2rem;

        &.col-primary {
            .right-arrow-btn,
            .left-arrow-btn {
                border: 0.5em solid $col-light-blue;
                background-color: $col-light-blue;
            }
        }
        &.col-secondary {
            .right-arrow-btn,
            .left-arrow-btn {
                border: 0.5em solid $col-light-green;
                background-color: $col-light-green;
            }
        }
        &.col-light-blue {
            .right-arrow-btn,
            .left-arrow-btn {
                border: 0.5em solid $col-light-blue;
                background-color: $col-light-blue;
            }
        }
        &.col-yellow {
            .right-arrow-btn,
            .left-arrow-btn {
                border: 0.5em solid $col-light-yellow;
                background-color: $col-light-yellow;
            }
        }
        &.col-pink {
            .right-arrow-btn,
            .left-arrow-btn {
                border: 0.5em solid $col-light-pink;
                background-color: $col-light-pink;
            }
        }
        &.col-purple {
            .right-arrow-btn,
            .left-arrow-btn {
                border: 0.5em solid $col-light-purple;
                background-color: $col-light-purple;
            }
        }
        &.col-light-purple {
            .right-arrow-btn,
            .left-arrow-btn {
                border: 0.5em solid $col-light-purple;
                background-color: $col-light-purple;
            }
        }
    }

    .row {
        position: relative;
        flex-grow: 1;
    }

    .hero-title {
        width: 150%;
        font-size: 1.5rem;

        .pre-title {
            font-size: 1.5rem;
        }
        @include media-breakpoint-up(sm) {
            width: 190%;
        }

        @include media-breakpoint-up(md) {
            width: 120%;
            font-size: 2.5rem;
        }

        @include media-breakpoint-up(lg) {
            width: 140%;
        }
        @include media-breakpoint-up(xl) {
            width: 115%;
        }
    }

    .card-img-right {
        margin: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        overflow: hidden;

        .img-container {
            display: flex;
            align-items: center;
            position: relative;
            height: 80%;
            width: 50%;
            transform: translate(110%, 15%);

            @include media-breakpoint-up(md) {
                width: 60%;
                height: 80%;
                transform: translate(75%, 15%);
            }

            .card-img {
                max-height: unset;
                height: 100%;
            }
        }
        &.show-all-content-md {
            @include media-breakpoint-down(md) {
                .img-container {
                    width: 100%;
                    height: 100%;
                    transform: translate(0, 0);
                }
            }
        }
    }

    .left-container {
        padding: 1.5rem 0 1.5rem 2rem;
        min-height: 100%;
        flex-grow: 1;
        z-index: 2;
        max-width: 600px;

        .hero-text-content {
            padding-bottom: 0em;
            @include media-breakpoint-up(md) {
                padding-bottom: 5em;
            }
        }

        &.show-all-content-md {
            @include media-breakpoint-down(md) {
                .hero-text-content {
                    display: none;
                }
            }
        }
    }

    .card-img-overlay {
        // padding: 2rem;
    }

    &.myguide-hero-card {
        @include media-breakpoint-up(md) {
            min-height: 400px;
        }

        .card-img-right .img-container {
            height: 100%;
            width: 70%;
            transform: translate(64%, 7%);

            @include media-breakpoint-up(md) {
                width: 60%;
                height: 100%;
                transform: translate(76%, 0%);
            }
        }
        .hero-title {
            width: 150%;

            @include media-breakpoint-up(sm) {
                width: 100%;
            }
        }
    }

    &.subsection-hero-card {
        position: relative;
        @include media-breakpoint-up(sm) {
            max-height: 300px;
        }
        @include media-breakpoint-up(md) {
            min-height: 350px;
            max-height: 350px;
        }
        @include media-breakpoint-up(lg) {
            min-height: 450px;
            max-height: 450px;
        }

        #hero-img {
            width: 100%;
            height: 100%;
            position: absolute;

            .card-img {
                max-height: 100%;
                @include media-breakpoint-up(sm) {
                    height: 290px;
                }
                @include media-breakpoint-up(md) {
                    height: 300px;
                }
                @include media-breakpoint-up(lg) {
                    height: 400px;
                }
            }
        }

        .hero-bg-img {
            position: absolute;
            width: 100%;
            height: 100%;

            svg {
                width: 90%;
                height: 100%;
                padding-left: 10%;
            }
        }
    }
}

.responsive-text-normal {
    display: none;
    @include media-breakpoint-up(md) {
        display: block;
    }
}

.responsive-text-short {
    display: block;
    @include media-breakpoint-up(md) {
        display: none;
    }
}

// Hero Card buttons
.hero-btn-container-md {
    position: absolute;
    bottom: 0;
    margin-bottom: 2rem;
    @include media-breakpoint-down(md) {
        position: absolute;
        // bottom: -34px;
        bottom: -60px;
        width: 100%;
        left: 0;

        .launch-btn {
            padding: 1rem;
            font-weight: 600;
            height: 60px;
            max-width: 220px;
            color: #000;
            font-size: 2rem;
            line-height: 1.5rem;
            background-color: $col-secondary;

            .launch-icon {
                circle {
                    fill: #fff;
                }
            }
        }

        .right-arrow-btn,
        .left-arrow-btn {
            width: 3.5em;
            height: 3.5em;

            &:after {
                margin-top: 0.6em;
                border-top: 0.15em solid #000;
                border-right: 0.15em solid #000;
            }
        }
    }

    &.launch-btn-container {
        @include media-breakpoint-down(md) {
            // bottom: -60px;
        }
    }

    .small-btn-container {
        width: 100%;
        @include media-breakpoint-up(lg) {
            width: 80%;
        }
    }

    .small-btn {
        width: 100%;
        font-size: 1rem;
        @include media-breakpoint-up(sm) {
            font-size: 1.5rem;
        }
    }
}

.time-container {
    span {
        font-size: 0.875rem;
        line-height: 18px;
    }
    .icon-btn {
        max-width: 18px;
        max-height: 18px;
    }
    @include media-breakpoint-up(sm) {
        span {
            font-size: 1em;
            line-height: 32px;
            font-weight: 200;
        }
        .icon-btn {
            max-width: 32px;
            max-height: 32px;
        }
    }

    @include media-breakpoint-up(md){
        padding-left: 1rem;
    }
}
