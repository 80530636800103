.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    z-index: 1000;
    pointer-events: none;
    padding: 2rem;

    &.invisible,
    &.invisible * {
        visibility: hidden !important;
    }
}

.popup {
    position: relative;
    background-color: $col-white;
    padding: 1rem;
    width: 100%;
    max-width: 800px;
    max-height: 800px;
    height: fit-content;
    margin: auto;
    pointer-events: visible;

    &.rq-edit {
        padding: 3rem;
        height: 80vh;
        max-height: 80vh;

        h3 {
            font-weight: 600;
            padding: 1rem;
            padding-left: 0;
        }
        .popup-info {
            padding: 0 1rem 0 0;
        }
        .rq-activity {
            padding-bottom: 1rem;
            line-height: normal;
        }
        .rq-edit-list-container {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 50vh;
        }

        @include media-breakpoint-up(md) {
            padding: 3rem;
            height: fit-content;
            max-height: 80vh;

            h3 {
                font-weight: 600;
                padding: 1rem;
            }
            .popup-info {
                padding: 0 1rem;
            }
            .rq-activity {
                padding-bottom: 1rem;
                line-height: normal;
            }
            .rq-edit-list-container {
                overflow-y: auto;
                height: 70vh;
            }
        }
    }
}

.close-popup-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    transform: scale(0.5);
    &:hover {
        cursor: pointer;
    }
}

// COOKIE BANNER
.banner {
    color: $light;
    background-color: $col-primary;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1em 1.8em;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    min-height: 7rem;
    min-width: 300px;

    position: fixed;
    overflow: hidden;
    flex-wrap: wrap;
    z-index: 9999;
    transition: none;

    @include media-breakpoint-down(md) {
        flex-wrap: nowrap;
    }

    .small-btn {
        transition: none;
    }
}

.bottom-banner {
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.6rem 0.6rem 0 0 !important;
    padding-top: 1.6rem !important;
    padding-bottom: 1.6rem !important;
}

.banner-message {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-right: 1em;
}

a.banner-link {
    color: $light;
    text-decoration: underline;
    &:hover {
        color: #fff;
        cursor: pointer;
    }
}

.banner-button-container {
    .small-btn {
        font-size: 1.25rem;
        width: 20%;

        &:hover,
        &:focus {
            background-color: $col-secondary;
            border-color: $col-secondary;
            color: $normal-text;
        }
    }
}

// HELP POPUP
#help-popup,
#welcome-popup {
    .popup {
        display: flex;
        min-width: 400px;
        max-width: 620px;
        width: 90vw;
        height: 90%;

        @include media-breakpoint-up(md) {
            height: 75%;
        }
    }

    .glider-contain {
        width: 100%;
        height: 100%;
    }

    .glider {
        height: 75%;
        width: 100%;
    }

    .glider-track {
        height: 100%;
    }

    .glider-slide {
        min-width: 300px;
        height: 100%;
    }
    .glider-dots {
        margin-top: 5rem;
    }

    .small-btn {
        top: 75%;
        width: 40%;
        transition: none;

        @include media-breakpoint-down(sm) {
            height: 2.5rem;
            font-size: 1.25rem;
        }

        &.glider-next,
        &.glider-prev {
            transform: translateY(0);
            transition: transform 0.1s linear;
        }

        &.glider-next {
            right: 0;

            &:hover,
            &:focus {
                transform: translateX(6px);
            }
        }
        &.glider-prev {
            left: 0;
            &:hover {
                transform: translateX(-6px);
            }
        }
    }

    .instruction-container {
        background-color: $col-light-blue;
        border-radius: 6px;
        border: 3px $col-light-blue solid;
        padding: 0.5rem 0.35rem 0.05rem;
    }

    .kbInstruction {
        color: $normal-text;
        justify-content: space-between;
        padding-right: calc(var(--bs-gutter-x) * 0.25);
        padding-left: calc(var(--bs-gutter-x) * 0.25);
    }
    h2 {
        font-size: 2.5rem;
    }
    .subtext {
        font-size: 0.95rem;
        margin-bottom: 0;
        margin-top: 3rem;
    }
}

.popup-btns-container {
    .small-btn {
        top: 75%;
        width: 100%;
        transition: none;
        font-size: 1rem;

        @include media-breakpoint-up(sm) {
            width: 40%;
            height: 2.5rem;
            font-size: 1.25rem;
            line-height: 1.5rem;
        }

        &.glider-next {
            right: 0;
        }
        &.glider-prev {
            left: 0;
        }
    }
}

.slidePanelContainer {
    width: 100%;
    height: 100%;
    padding: 1.8rem 9.4rem;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    flex: 0 1 auto;
    text-align: center;

    @include media-breakpoint-up(md) {
        padding: 0 2rem;
    }

    h2,
    h3,
    h4 {
        margin: 0;
    }

    h3 {
        font-weight: 600;
        text-align: center;
        margin: 0 auto 1.6rem auto;
        line-height: 1.6rem;

        @include media-breakpoint-up(md) {
            line-height: 2.2rem;
        }
    }

    h4 {
        text-align: left;
    }

    p.captionP {
        margin: 0;
    }

    #welcomePanel {
        margin: 0 auto;
        padding: 0 2rem;

        @include media-breakpoint-up(md) {
            margin-top: 3.8rem;
        }

        h2 {
            font-size: 1.875rem;
            margin-bottom: 2rem;
            margin-top: 0;

            @include media-breakpoint-up(md) {
                font-size: 3.375rem;
            }
        }
    }
}
