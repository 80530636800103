#survey_popup {
    p {
        padding-top: 0;
        margin-bottom: 0.5rem;
    }
    #name-input {
        width: 100%;
        border-radius: 10px;
        padding: 12px;
        text-align: center;
        border: 1px solid black;
        margin-bottom: 1em;
    }
    .name-btn-container {
        position: absolute;
        width: 100%;
        left: 0;
    }
    #name-submit-btn {
        background-color: $col-secondary;
        border-color: $col-secondary;
        max-width: 200px;
        margin: 1rem auto 0;
        &:hover {
            color: $normal-text;
            box-shadow: 0 6px 20px $shadow-lighter;
        }
    }
    .card {
        &.col-light-purple {

        }
    }
    .img-card {
        height: 100%;
    }
}
