table.fav-table {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 400;
    tr {
        border-bottom: 1px solid $col-grey;
    }

    th {
        text-transform: uppercase;
        color: $col-grey;
        font-weight: 200;
    }

    td {

        a {
            text-decoration: none;
            color: $normal-text;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    td, th {
        padding: 2rem 3rem;
    }

    .icon-btn {
        width: 24px;
        height: 24px;
    }

    .remove-col {
        text-align: center;
    }
}