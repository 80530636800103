.rq-activity {
    @include media-breakpoint-down(md) {
        ul {
            padding-left: 0;
        }
        .input-label {
        }
    }
}

.rq-list-heading {
    padding-left: 1rem;
    margin: auto;
    font-weight: 600;
    margin-top: 1rem;

    @include media-breakpoint-up(md) {
        margin-top: auto;
    }

    a {
        text-decoration: none;
        color: inherit;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &.disabled {
        color: $col-grey;
    }
}

.rq-list {
    margin-bottom: 4rem;

    ol {
        padding-left: 0.25rem;
        @include media-breakpoint-up(md) {
            padding-left: 1.5rem;
        }
    }
}

.rq-list-item {
    p {
        margin-bottom: 0.75rem;
        @include media-breakpoint-up(md) {
            margin-bottom: 1rem;
        }
    }
    ul {
        margin: -0.25rem 0 0.5rem;
    }
    li {
        list-style: disc;
        font-size: inherit;
        font-size: 0.875em;
        @include media-breakpoint-up(md) {
            font-size: 1em;
        }
    }
}

.rq-btn-container {
    padding: 0;
    justify-content: flex-end;

    @include media-breakpoint-up(md) {
        padding: 0 0.5rem;
    }
    button {
        margin: 0 0 0 auto;
        min-width: 100px;
        margin: 0.5rem 0.5rem 1rem 0;

        @include media-breakpoint-up(md) {
            min-width: 150px;
            margin-right: 0;
        }
    }
}
